import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import stage1timeline from "../../images/stage1-timeline.jpg"
import solhelp from "../../images/solhelpthefut.jpg"
import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Cable = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">CABLE Conductor Manufacturing Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                {" "}
                Help electrify innovation; join the CABLE Prize challenge today!
              </p>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                As electrification grows worldwide in response to the climate
                emergency, so too will the demand for conductivity-enhanced
                materials and applications. There is an urgent need for enhanced
                conductivity materials that can lower costs and improve grid
                performance—including resilience against extreme weather
                events—as well as the performance of everything connected to the
                grid. We have a once-in-a-generation window of opportunity to
                upgrade and expand our aging electric and transportation
                infrastructures with new, high-performance materials.
              </p>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                Conductivity-enhanced materials support transformational
                technologies ranging from electric cars, trains, and planes to
                smartphones, heat pumps, and everything else in our daily lives
                that involves the conduction of electric and thermal energy. For
                these reasons, the U.S. Department of Energy (DOE) Advanced
                Manufacturing Office (AMO) launched the Conductivity-enhanced
                materials for Affordable, Breakthrough Leapfrog Electric and
                thermal applications (CABLE) Conductor Manufacturing Prize to
                help supercharge our U.S. energy and manufacturing industries.
              </p>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The $4.5-million CABLE Prize encourages researchers and
                inventors to develop and manufacture breakthrough
                conductivity-enhanced materials. Competitors must design
                affordable conductors that demonstrate significant enhancements
                in conductivity and enable U.S. manufacturers to leapfrog to
                next-generation materials. Up to four Grand Prize winners will
                be selected in this three-stage, three-year contest.
              </p>
              <p style={{ textAlign: "justify" }}>
                <a href="" style={{ color: "white" }}>
                  Learn more about this prize
                </a>
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/CABLE">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy" />
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">The Cable Prize</h1>
          <p className="pcol-fz">
            Conductive materials are fundamental to nearly all energy use
            applications. Developing manufacturing processes for
            conductivity-enhanced materials in motors, generators, and renewable
            power technologies could lower costs and reduce climate impacts
            while improving performance.{" "}
          </p>
          <p className="pcol-fz">
            The competition aims to unite a diverse collection of researchers
            and inventors to develop transformative, cost-effective approaches
            and technologies to accelerate American entrepreneurship and
            pinpoint pathways for further research and development funding and
            technology transition efforts.
          </p>
          <div className="col">
            <StaticImage
              className="img-style"
              src="../../images/stage1-timeline.jpg"
              alt="stage 1 timeline"
              loading="lazy"
            />
          </div>
        </div>
        <span className="whitespace-half" style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h2 className="mac_h1"> Stage 1:</h2>
            <hr />
            <p className="pcol-fz">
              Competitors will submit their breakthrough concepts to develop and
              manufacture a new, affordable, conductivity-enhanced material.
              This stage of the prize will inform DOE about the minimum
              conductivity enhancement and other property standards, as well as
              the types of support that competitors will likely need from DOE
              national laboratories or other American-Made Challenges Network
              providers in the next two stages of the prize. Up to 10 winners
              will each receive $25,000 in cash awards and a stipend for
              third-party testing of their material in Stage 2 of the prize. DOE
              invites all registered prize competitors to the upcoming CABLE Big
              Idea workshop during Stage 1. Prize competitors are encouraged to
              connect with the rest of the CABLE Big Idea innovation ecosystem.
            </p>
            <h2 className="mac_h1">Important Dates</h2>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b className="li-p">Contest Begins and Registration Opens:</b>{" "}
                March 17, 2021
              </li>
              <li>
                <a
                  className="li-p"
                  href="https://nrel.webex.com/nrel/onstage/g.php?MTID=e7c8ca65846b3cb32f4f61ec757259554"
                >
                  Contest Webinar
                </a>
                : March 30, 2021
              </li>
              <li>
                <a
                  className="li-p"
                  href="https://cable-bigidea.anl.gov/workshop/"
                >
                  CABLE Big Idea Workshop
                </a>
                : April 7-9, 2021
              </li>
              <li>
                <b className="li-p">Contest Submission Deadline:</b> June 8,
                2021, 5:00 p.m. ET
              </li>
              <li>
                <b className="li-p">Winners Expected Announcement:</b> August
                2021
              </li>
            </ul>
            <div>
              <h2 className="mac_h1"> Stage 2:</h2>
              <hr />
              <p className="pcol-fz">
                Competitors will provide a sample of their material for
                electrical conductivity testing according to prize requirements.
                Competitors will also provide preliminary plans to scale up and
                manufacture the material. Up to six competitors will each win
                $200,000 in cash awards and $100,000 in noncash voucher support
                to work with a DOE national laboratory or other American-Made
                Challenges Network provider in Stage 3.
              </p>
              <h2 className="mac_h1">Important Dates</h2>
              <ul className="boxy-bullets gold-bullets ps list-styled">
                <li>
                  <b className="li-p">Contest Begins:</b> October 2021*
                </li>
                <li>
                  <b className="li-p">Contest Submission Deadline:</b> July
                  2022*
                </li>
                <li>
                  <b className="li-p">Winners Expected Announcement:</b> August
                  2022*
                </li>
              </ul>
              <p>* Dates anticipated</p>
            </div>

            <div>
              <h2 className="mac_h1"> Stage 3:</h2>
              <hr />
              <p className="pcol-fz">
                Competitors successful in Stage 2 will develop a larger sample
                of their conductivity-enhanced material. Competitors must also
                provide substantial background information on how the sample was
                made and plans to commercialize the material. At least two
                testing organizations will evaluate each material sample for
                conductivity and other characteristics. Scores will be based, in
                part, on the conductivity enhancement (size and extent of
                breakthrough), other important material characteristics, and
                leapfrog manufacturability and affordability. Up to four
                competitors will split a total prize pool of at least
                $2,000,000.
              </p>
              <h2 className="mac_h1">Important Dates</h2>
              <ul className="boxy-bullets gold-bullets ps list-styled">
                <li>
                  <b className="li-p">Contest Begins:</b> October 2022*
                </li>
                <li>
                  <b className="li-p">Contest Submission Deadline:</b> August
                  2023*
                </li>
                <li>
                  <b className="li-p">Winners Expected Announcement:</b> October
                  2023*
                </li>
              </ul>
              * Dates anticipated
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <h2 className="mac_h1">Who Can Participate</h2>
          <hr />
          <p className="pcol-fz">
            Answer the call; help us enhance electrical and thermal conductivity
            though innovation. Competitors are entrepreneurial individuals or
            teams legally residing or based in the United States. Competitors
            can be individuals of one or multiple organizations, students,
            university faculty members, small business owners, researchers, or
            anyone with the desire and drive to transform an idea into an
            impactful conductor material.{" "}
            <a href="../../CABLE_Prize_Official_Rules.pdf" target="_blank">
              Read the CABLE Prize Rules to learn more.
            </a>
          </p>
          <h3 className="mac_h1">How to Enter</h3>
          <p className="pcol-fz">
            Complete a submission package online at our{" "}
            <a href="https://www.herox.com/CABLE">HeroX challenge page.</a>
          </p>
          <p className="pcol-fz">
            All dates are subject to change including contest openings,
            deadlines, and announcements. Sign up for updates at{" "}
            <a href="https://www.herox.com/CABLE">
              https://www.herox.com/cable
            </a>
            .
          </p>
        </div>
        <span className="whitespace-half" style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps"> Solutions that help shape the future </h3>
          <StaticImage
            className="img-styl"
            src="../../images/solhelpthefut.jpg"
            alt="help the fruit"
            loading="lazy"
          />
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/CABLE">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Cable
